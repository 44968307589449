/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Persons2Data,
  Persons3Data,
  Persons3Params,
  PersonsAddNonFamilyRelationshipData,
  PersonsAddNonFamilyRelationshipPayload,
  PersonsAlllifeeventsData,
  PersonsAlllifeeventsParams,
  PersonsAttachRecordData,
  PersonsAttachRecordPayload,
  PersonsBirthdateData,
  PersonsBirthdatePayload,
  PersonsBirthlocationData,
  PersonsBirthlocationPayload,
  PersonsCheckAssociationData,
  PersonsChildData,
  PersonsChildPayload,
  PersonsCollectionidofhighestscoringhintData,
  PersonsCompareData,
  PersonsContentsearchData,
  PersonsData,
  PersonsDateData,
  PersonsDatePayload,
  PersonsDeathdateData,
  PersonsDeathdatePayload,
  PersonsDeathlocationData,
  PersonsDeathlocationPayload,
  PersonsDeletebackgroundimageData,
  PersonsDeletepersonData,
  PersonsDeletepersonassertionData,
  PersonsDeletepersonassertionPayload,
  PersonsDeletepetData,
  PersonsDeleteprofileimageData,
  PersonsDeletespousalassertionData,
  PersonsDeletespousalassertionPayload,
  PersonsDivorcedateData,
  PersonsDivorcedatePayload,
  PersonsDivorcelocationData,
  PersonsDivorcelocationPayload,
  PersonsExpandtreeData,
  PersonsFatherData,
  PersonsFatherPayload,
  PersonsFullnameData,
  PersonsFullnamePayload,
  PersonsGenderData,
  PersonsGenderPayload,
  PersonsGetDirectChildrenData,
  PersonsGetFamilySearchHintData,
  PersonsGetFamilySearchHintParams,
  PersonsGetlivingstatusofapersontobeaddedData,
  PersonsGivennameData,
  PersonsGivennamePayload,
  PersonsHintcountData,
  PersonsHintcountParams,
  PersonsImmediatefamilyData,
  PersonsImmediatefamilyParams,
  PersonsInferredlocationidData,
  PersonsInferredlocationidParams,
  PersonsInfoData,
  PersonsIstreeaccessibleData,
  PersonsIstreeaccessibleParams,
  PersonsLifeeventData,
  PersonsLifeeventPayload,
  PersonsLifeevents2Data,
  PersonsLifeevents2Params,
  PersonsLifeeventsData,
  PersonsLocationData,
  PersonsLocationPayload,
  PersonsMarriagedateData,
  PersonsMarriagedatePayload,
  PersonsMarriagelocationData,
  PersonsMarriagelocationPayload,
  PersonsMotherData,
  PersonsMotherPayload,
  PersonsNewpersonhintData,
  PersonsNewspaperMatchesData,
  PersonsNewspaperMatchesDismissData,
  PersonsNewspaperMatchesDismissPayload,
  PersonsNewspaperMatchesParams,
  PersonsNonfamilyrelationshipsData,
  PersonsNonfamilyrelationshipsParams,
  PersonsParentData,
  PersonsParentPayload,
  PersonsParentsinfoData,
  PersonsParentsinfoParams,
  PersonsParentswithsiblingsData,
  PersonsParentswithsiblingsParams,
  PersonsPayload,
  PersonsPedigreeData,
  PersonsPedigreeprogressmeterinfoData,
  PersonsPendingignoredhintsData,
  PersonsPersonAndRecordPersonAssociationData,
  PersonsPersonAndRecordPersonAssociationPayload,
  PersonsPersonData,
  PersonsPersonPayload,
  PersonsPersonStoriesData,
  PersonsPersonStoriesParams,
  PersonsPersonhintData,
  PersonsPersonlifeeventsData,
  PersonsPersonlifeeventsParams,
  PersonsPersonmedialistData,
  PersonsPersonmedialistParams,
  PersonsPersonrecordsData,
  PersonsPersonstoriescountbycategoriesData,
  PersonsPersonstoriescountbycategoriesParams,
  PersonsPetData,
  PersonsPetPayload,
  PersonsPotentialparentData,
  PersonsPotentialparentPayload,
  PersonsPotentialpersonhintsData,
  PersonsPotentialpersonhintsParams,
  PersonsRecordPersonInfoData,
  PersonsRegisterPetOwnershipData,
  PersonsRegisterPetOwnershipPayload,
  PersonsRejectnewpersonhintData,
  PersonsRejectnewpersonhintPayload,
  PersonsRelationshipsData,
  PersonsRelationshipsParams,
  PersonsRemoveRecordData,
  PersonsRemoveRecordPayload,
  PersonsSaveRecordPersonMergeData,
  PersonsSaveRecordPersonMergePayload,
  PersonsSavedrecordsData,
  PersonsSearchlifeeventsData,
  PersonsSearchlifeeventsParams,
  PersonsSiblingData,
  PersonsSiblingPayload,
  PersonsSiblingsSharingSingleParentData,
  PersonsSpousaldateData,
  PersonsSpousaldatePayload,
  PersonsSpousallocationData,
  PersonsSpousallocationPayload,
  PersonsSpousalrelationshipeventData,
  PersonsSpousalrelationshipeventPayload,
  PersonsSpouseData,
  PersonsSpousePayload,
  PersonsSpousesData,
  PersonsSpouseswithchildrenData,
  PersonsSpouseswithchildrenParams,
  PersonsSurnameData,
  PersonsSurnamePayload,
  PersonsTreePersonInfoData,
  V2PersonsAllLifeEventsData,
  V2PersonsAllLifeEventsParams,
  V2PersonsAlllifeeventsData,
  V2PersonsAlllifeeventsParams,
  V2PersonsLifeeventsData,
  V2PersonsMediaData,
  V2PersonsMediaPayload,
  V2PersonsMediaPeopleData,
  V2PersonsMediaPeoplePayload,
  V2PersonsMergeRecordData,
  V2PersonsMergeRecordPayload,
  V2PersonsParentswithsiblingsData,
  V2PersonsParentswithsiblingsParams,
  V2PersonsPersonMediaListData,
  V2PersonsPersonMediaListParams
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Persons extends HttpClient {
  /**
   * No description
   *
   * @tags Persons
   * @name PersonsGivenname
   * @request PUT:/api/Persons/givenname
   * @secure
   */
  personsGivenname = (data: PersonsGivennamePayload, params: RequestParams = {}) =>
    this.request<PersonsGivennameData, any>({
      path: `/api/Persons/givenname`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSurname
   * @request PUT:/api/Persons/surname
   * @secure
   */
  personsSurname = (data: PersonsSurnamePayload, params: RequestParams = {}) =>
    this.request<PersonsSurnameData, any>({
      path: `/api/Persons/surname`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsFullname
   * @request PUT:/api/Persons/fullname
   * @secure
   */
  personsFullname = (data: PersonsFullnamePayload, params: RequestParams = {}) =>
    this.request<PersonsFullnameData, any>({
      path: `/api/Persons/fullname`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsGender
   * @request PUT:/api/Persons/gender
   * @secure
   */
  personsGender = (data: PersonsGenderPayload, params: RequestParams = {}) =>
    this.request<PersonsGenderData, any>({
      path: `/api/Persons/gender`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsBirthdate
   * @request PUT:/api/Persons/birthdate
   * @secure
   */
  personsBirthdate = (data: PersonsBirthdatePayload, params: RequestParams = {}) =>
    this.request<PersonsBirthdateData, any>({
      path: `/api/Persons/birthdate`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeathdate
   * @request PUT:/api/Persons/deathdate
   * @secure
   */
  personsDeathdate = (data: PersonsDeathdatePayload, params: RequestParams = {}) =>
    this.request<PersonsDeathdateData, any>({
      path: `/api/Persons/deathdate`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsMarriagedate
   * @request PUT:/api/Persons/marriagedate
   * @secure
   */
  personsMarriagedate = (data: PersonsMarriagedatePayload, params: RequestParams = {}) =>
    this.request<PersonsMarriagedateData, any>({
      path: `/api/Persons/marriagedate`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsMarriagelocation
   * @request PUT:/api/Persons/marriagelocation
   * @secure
   */
  personsMarriagelocation = (data: PersonsMarriagelocationPayload, params: RequestParams = {}) =>
    this.request<PersonsMarriagelocationData, any>({
      path: `/api/Persons/marriagelocation`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDivorcedate
   * @request PUT:/api/Persons/divorcedate
   * @secure
   */
  personsDivorcedate = (data: PersonsDivorcedatePayload, params: RequestParams = {}) =>
    this.request<PersonsDivorcedateData, any>({
      path: `/api/Persons/divorcedate`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDivorcelocation
   * @request PUT:/api/Persons/divorcelocation
   * @secure
   */
  personsDivorcelocation = (data: PersonsDivorcelocationPayload, params: RequestParams = {}) =>
    this.request<PersonsDivorcelocationData, any>({
      path: `/api/Persons/divorcelocation`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSpousaldate
   * @request PUT:/api/Persons/spousaldate
   * @secure
   */
  personsSpousaldate = (data: PersonsSpousaldatePayload, params: RequestParams = {}) =>
    this.request<PersonsSpousaldateData, any>({
      path: `/api/Persons/spousaldate`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSpousallocation
   * @request PUT:/api/Persons/spousallocation
   * @secure
   */
  personsSpousallocation = (data: PersonsSpousallocationPayload, params: RequestParams = {}) =>
    this.request<PersonsSpousallocationData, any>({
      path: `/api/Persons/spousallocation`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDate
   * @request PUT:/api/Persons/date
   * @secure
   */
  personsDate = (data: PersonsDatePayload, params: RequestParams = {}) =>
    this.request<PersonsDateData, any>({
      path: `/api/Persons/date`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsLocation
   * @request PUT:/api/Persons/location
   * @secure
   */
  personsLocation = (data: PersonsLocationPayload, params: RequestParams = {}) =>
    this.request<PersonsLocationData, any>({
      path: `/api/Persons/location`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsBirthlocation
   * @request PUT:/api/Persons/birthlocation
   * @secure
   */
  personsBirthlocation = (data: PersonsBirthlocationPayload, params: RequestParams = {}) =>
    this.request<PersonsBirthlocationData, any>({
      path: `/api/Persons/birthlocation`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeathlocation
   * @request PUT:/api/Persons/deathlocation
   * @secure
   */
  personsDeathlocation = (data: PersonsDeathlocationPayload, params: RequestParams = {}) =>
    this.request<PersonsDeathlocationData, any>({
      path: `/api/Persons/deathlocation`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name Persons
   * @request PUT:/api/Persons/{personid}
   * @secure
   */
  persons = (personid: string, data: PersonsPayload, params: RequestParams = {}) =>
    this.request<PersonsData, any>({
      path: `/api/Persons/${personid}`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsNewspaperMatchesDismiss
   * @request PUT:/api/Persons/newspaper-matches/dismiss
   * @secure
   */
  personsNewspaperMatchesDismiss = (
    data: PersonsNewspaperMatchesDismissPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsNewspaperMatchesDismissData, any>({
      path: `/api/Persons/newspaper-matches/dismiss`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsInferredlocationid
   * @request PUT:/api/Persons/inferredlocationid
   * @secure
   */
  personsInferredlocationid = (
    query: PersonsInferredlocationidParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsInferredlocationidData, any>({
      path: `/api/Persons/inferredlocationid`,
      method: "PUT",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsFather
   * @request POST:/api/Persons/father
   * @secure
   */
  personsFather = (data: PersonsFatherPayload, params: RequestParams = {}) =>
    this.request<PersonsFatherData, any>({
      path: `/api/Persons/father`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsChild
   * @request POST:/api/Persons/child
   * @secure
   */
  personsChild = (data: PersonsChildPayload, params: RequestParams = {}) =>
    this.request<PersonsChildData, any>({
      path: `/api/Persons/child`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSibling
   * @request POST:/api/Persons/sibling
   * @secure
   */
  personsSibling = (data: PersonsSiblingPayload, params: RequestParams = {}) =>
    this.request<PersonsSiblingData, any>({
      path: `/api/Persons/sibling`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSpouse
   * @request POST:/api/Persons/spouse
   * @secure
   */
  personsSpouse = (data: PersonsSpousePayload, params: RequestParams = {}) =>
    this.request<PersonsSpouseData, any>({
      path: `/api/Persons/spouse`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsLifeevent
   * @request POST:/api/Persons/lifeevent
   * @secure
   */
  personsLifeevent = (data: PersonsLifeeventPayload, params: RequestParams = {}) =>
    this.request<PersonsLifeeventData, any>({
      path: `/api/Persons/lifeevent`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSpousalrelationshipevent
   * @request POST:/api/Persons/spousalrelationshipevent
   * @secure
   */
  personsSpousalrelationshipevent = (
    data: PersonsSpousalrelationshipeventPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsSpousalrelationshipeventData, any>({
      path: `/api/Persons/spousalrelationshipevent`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsMother
   * @request POST:/api/Persons/mother
   * @secure
   */
  personsMother = (data: PersonsMotherPayload, params: RequestParams = {}) =>
    this.request<PersonsMotherData, any>({
      path: `/api/Persons/mother`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsParent
   * @request POST:/api/Persons/parent
   * @secure
   */
  personsParent = (data: PersonsParentPayload, params: RequestParams = {}) =>
    this.request<PersonsParentData, any>({
      path: `/api/Persons/parent`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPotentialparent
   * @request POST:/api/Persons/potentialparent
   * @secure
   */
  personsPotentialparent = (data: PersonsPotentialparentPayload, params: RequestParams = {}) =>
    this.request<PersonsPotentialparentData, any>({
      path: `/api/Persons/potentialparent`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPet
   * @request POST:/api/Persons/pet
   * @secure
   */
  personsPet = (data: PersonsPetPayload, params: RequestParams = {}) =>
    this.request<PersonsPetData, any>({
      path: `/api/Persons/pet`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsRegisterPetOwnership
   * @request POST:/api/Persons/registerPetOwnership
   * @secure
   */
  personsRegisterPetOwnership = (
    data: PersonsRegisterPetOwnershipPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsRegisterPetOwnershipData, any>({
      path: `/api/Persons/registerPetOwnership`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPerson
   * @request POST:/api/Persons/person
   * @secure
   */
  personsPerson = (data: PersonsPersonPayload, params: RequestParams = {}) =>
    this.request<PersonsPersonData, any>({
      path: `/api/Persons/person`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeleteprofileimage
   * @request DELETE:/api/Persons/deleteprofileimage/{personId}
   * @secure
   */
  personsDeleteprofileimage = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsDeleteprofileimageData, any>({
      path: `/api/Persons/deleteprofileimage/${personId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeletebackgroundimage
   * @request DELETE:/api/Persons/deletebackgroundimage/{personId}
   * @secure
   */
  personsDeletebackgroundimage = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsDeletebackgroundimageData, any>({
      path: `/api/Persons/deletebackgroundimage/${personId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeletepersonassertion
   * @request DELETE:/api/Persons/deletepersonassertion
   * @secure
   */
  personsDeletepersonassertion = (
    data: PersonsDeletepersonassertionPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsDeletepersonassertionData, any>({
      path: `/api/Persons/deletepersonassertion`,
      method: "DELETE",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeletespousalassertion
   * @request DELETE:/api/Persons/deletespousalassertion
   * @secure
   */
  personsDeletespousalassertion = (
    data: PersonsDeletespousalassertionPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsDeletespousalassertionData, any>({
      path: `/api/Persons/deletespousalassertion`,
      method: "DELETE",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeleteperson
   * @request DELETE:/api/Persons/deleteperson/{personId}
   * @secure
   */
  personsDeleteperson = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsDeletepersonData, any>({
      path: `/api/Persons/deleteperson/${personId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsDeletepet
   * @request DELETE:/api/Persons/deletepet/{ownerId}/{petId}
   * @secure
   */
  personsDeletepet = (ownerId: string, petId: string, params: RequestParams = {}) =>
    this.request<PersonsDeletepetData, any>({
      path: `/api/Persons/deletepet/${ownerId}/${petId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsAddNonFamilyRelationship
   * @request POST:/api/Persons/AddNonFamilyRelationship
   * @secure
   */
  personsAddNonFamilyRelationship = (
    data: PersonsAddNonFamilyRelationshipPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsAddNonFamilyRelationshipData, any>({
      path: `/api/Persons/AddNonFamilyRelationship`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsAttachRecord
   * @request POST:/api/Persons/attachRecord
   * @secure
   */
  personsAttachRecord = (data: PersonsAttachRecordPayload, params: RequestParams = {}) =>
    this.request<PersonsAttachRecordData, any>({
      path: `/api/Persons/attachRecord`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsRemoveRecord
   * @request POST:/api/Persons/removeRecord
   * @secure
   */
  personsRemoveRecord = (data: PersonsRemoveRecordPayload, params: RequestParams = {}) =>
    this.request<PersonsRemoveRecordData, any>({
      path: `/api/Persons/removeRecord`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSaveRecordPersonMerge
   * @request POST:/api/Persons/saveRecordPersonMerge
   * @secure
   */
  personsSaveRecordPersonMerge = (
    data: PersonsSaveRecordPersonMergePayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsSaveRecordPersonMergeData, any>({
      path: `/api/Persons/saveRecordPersonMerge`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonAndRecordPersonAssociation
   * @request POST:/api/Persons/personAndRecordPersonAssociation
   * @secure
   */
  personsPersonAndRecordPersonAssociation = (
    data: PersonsPersonAndRecordPersonAssociationPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPersonAndRecordPersonAssociationData, any>({
      path: `/api/Persons/personAndRecordPersonAssociation`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsRejectnewpersonhint
   * @request POST:/api/Persons/rejectnewpersonhint
   * @secure
   */
  personsRejectnewpersonhint = (
    data: PersonsRejectnewpersonhintPayload,
    params: RequestParams = {}
  ) =>
    this.request<PersonsRejectnewpersonhintData, any>({
      path: `/api/Persons/rejectnewpersonhint`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsMergeRecord
   * @request POST:/api/v2/Persons/merge-record
   * @secure
   */
  v2PersonsMergeRecord = (data: V2PersonsMergeRecordPayload, params: RequestParams = {}) =>
    this.request<V2PersonsMergeRecordData, any>({
      path: `/api/v2/Persons/merge-record`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPedigree
   * @request GET:/api/Persons/pedigree/{treeId}/{personId}/{generations}
   * @secure
   */
  personsPedigree = (
    treeId: string,
    personId: string,
    generations: number,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPedigreeData, any>({
      path: `/api/Persons/pedigree/${treeId}/${personId}/${generations}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsExpandtree
   * @request GET:/api/Persons/expandtree/{treeId}/{personId}/{path}
   * @secure
   */
  personsExpandtree = (
    treeId: string,
    personId: string,
    path: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsExpandtreeData, any>({
      path: `/api/Persons/expandtree/${treeId}/${personId}/${path}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsInfo
   * @request GET:/api/Persons/{personId}/info
   * @secure
   */
  personsInfo = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsInfoData, any>({
      path: `/api/Persons/${personId}/info`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsTreePersonInfo
   * @request GET:/api/Persons/{personId}/treePersonInfo
   * @secure
   */
  personsTreePersonInfo = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsTreePersonInfoData, any>({
      path: `/api/Persons/${personId}/treePersonInfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name Persons2
   * @request GET:/api/Persons/{personId}
   * @originalName persons
   * @duplicate
   * @secure
   */
  persons2 = (personId: string, params: RequestParams = {}) =>
    this.request<Persons2Data, any>({
      path: `/api/Persons/${personId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsRecordPersonInfo
   * @request GET:/api/Persons/{recordPersonId}/recordPersonInfo
   * @secure
   */
  personsRecordPersonInfo = (recordPersonId: string, params: RequestParams = {}) =>
    this.request<PersonsRecordPersonInfoData, any>({
      path: `/api/Persons/${recordPersonId}/recordPersonInfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSpouseswithchildren
   * @request GET:/api/Persons/{treeId}/{personId}/spouseswithchildren
   * @secure
   */
  personsSpouseswithchildren = (
    { treeId, personId, ...query }: PersonsSpouseswithchildrenParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsSpouseswithchildrenData, any>({
      path: `/api/Persons/${treeId}/${personId}/spouseswithchildren`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSpouses
   * @request GET:/api/Persons/{treeId}/{personId}/spouses
   * @secure
   */
  personsSpouses = (treeId: string, personId: string, params: RequestParams = {}) =>
    this.request<PersonsSpousesData, any>({
      path: `/api/Persons/${treeId}/${personId}/spouses`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsCheckAssociation
   * @request GET:/api/Persons/{personId}/checkAssociation/{recordId}/{partitionKey}
   * @secure
   */
  personsCheckAssociation = (
    personId: string,
    recordId: string,
    partitionKey: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsCheckAssociationData, any>({
      path: `/api/Persons/${personId}/checkAssociation/${recordId}/${partitionKey}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSiblingsSharingSingleParent
   * @request GET:/api/Persons/{treeId}/{personId}/SiblingsSharingSingleParent/{parentId}
   * @secure
   */
  personsSiblingsSharingSingleParent = (
    treeId: string,
    personId: string,
    parentId: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsSiblingsSharingSingleParentData, any>({
      path: `/api/Persons/${treeId}/${personId}/SiblingsSharingSingleParent/${parentId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsGetDirectChildren
   * @request GET:/api/Persons/{treeId}/{personId}/GetDirectChildren
   * @secure
   */
  personsGetDirectChildren = (treeId: string, personId: string, params: RequestParams = {}) =>
    this.request<PersonsGetDirectChildrenData, any>({
      path: `/api/Persons/${treeId}/${personId}/GetDirectChildren`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsParentswithsiblings
   * @request GET:/api/Persons/{treeId}/{personId}/parentswithsiblings
   * @secure
   */
  personsParentswithsiblings = (
    { treeId, personId, ...query }: PersonsParentswithsiblingsParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsParentswithsiblingsData, any>({
      path: `/api/Persons/${treeId}/${personId}/parentswithsiblings`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsParentsinfo
   * @request GET:/api/Persons/{treeId}/{personId}/parentsinfo
   * @secure
   */
  personsParentsinfo = (
    { treeId, personId, ...query }: PersonsParentsinfoParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsParentsinfoData, any>({
      path: `/api/Persons/${treeId}/${personId}/parentsinfo`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsLifeevents
   * @request GET:/api/Persons/{treeId}/{personId}/lifeevents
   * @secure
   */
  personsLifeevents = (treeId: string, personId: string, params: RequestParams = {}) =>
    this.request<PersonsLifeeventsData, any>({
      path: `/api/Persons/${treeId}/${personId}/lifeevents`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsAlllifeevents
   * @request GET:/api/Persons/{personId}/alllifeevents
   * @secure
   */
  personsAlllifeevents = (
    { personId, ...query }: PersonsAlllifeeventsParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsAlllifeeventsData, any>({
      path: `/api/Persons/${personId}/alllifeevents`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSearchlifeevents
   * @request GET:/api/Persons/{personId}/searchlifeevents
   * @secure
   */
  personsSearchlifeevents = (
    { personId, ...query }: PersonsSearchlifeeventsParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsSearchlifeeventsData, any>({
      path: `/api/Persons/${personId}/searchlifeevents`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsCompare
   * @request GET:/api/Persons/{personId}/compare/{recordId}/{partitionKey}
   * @secure
   */
  personsCompare = (
    personId: string,
    recordId: string,
    partitionKey: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsCompareData, any>({
      path: `/api/Persons/${personId}/compare/${recordId}/${partitionKey}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name Persons3
   * @request GET:/api/Persons/{personId}/{recordPersonId}
   * @originalName persons
   * @duplicate
   * @secure
   */
  persons3 = ({ personId, recordPersonId, ...query }: Persons3Params, params: RequestParams = {}) =>
    this.request<Persons3Data, any>({
      path: `/api/Persons/${personId}/${recordPersonId}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonrecords
   * @request GET:/api/Persons/{personId}/personrecords
   * @secure
   */
  personsPersonrecords = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsPersonrecordsData, any>({
      path: `/api/Persons/${personId}/personrecords`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonStories
   * @request GET:/api/Persons/{personId}/{pageNumber}/{pageSize}/PersonStories
   * @secure
   */
  personsPersonStories = (
    { personId, pageNumber, pageSize, ...query }: PersonsPersonStoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPersonStoriesData, any>({
      path: `/api/Persons/${personId}/${pageNumber}/${pageSize}/PersonStories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonstoriescountbycategories
   * @request GET:/api/Persons/{personId}/personstoriescountbycategories
   * @secure
   */
  personsPersonstoriescountbycategories = (
    { personId, ...query }: PersonsPersonstoriescountbycategoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPersonstoriescountbycategoriesData, any>({
      path: `/api/Persons/${personId}/personstoriescountbycategories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsContentsearch
   * @request GET:/api/Persons/{personId}/contentsearch
   * @secure
   */
  personsContentsearch = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsContentsearchData, any>({
      path: `/api/Persons/${personId}/contentsearch`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonhint
   * @request GET:/api/Persons/{personId}/personhint
   * @secure
   */
  personsPersonhint = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsPersonhintData, any>({
      path: `/api/Persons/${personId}/personhint`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsRelationships
   * @request GET:/api/Persons/relationships
   * @secure
   */
  personsRelationships = (query: PersonsRelationshipsParams, params: RequestParams = {}) =>
    this.request<PersonsRelationshipsData, any>({
      path: `/api/Persons/relationships`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsNonfamilyrelationships
   * @request GET:/api/Persons/nonfamilyrelationships
   * @secure
   */
  personsNonfamilyrelationships = (
    query: PersonsNonfamilyrelationshipsParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsNonfamilyrelationshipsData, any>({
      path: `/api/Persons/nonfamilyrelationships`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsGetlivingstatusofapersontobeadded
   * @request GET:/api/Persons/{treeId}/{personId}/{relation}/getlivingstatusofapersontobeadded
   * @secure
   */
  personsGetlivingstatusofapersontobeadded = (
    treeId: string,
    personId: string,
    relation: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsGetlivingstatusofapersontobeaddedData, any>({
      path: `/api/Persons/${treeId}/${personId}/${relation}/getlivingstatusofapersontobeadded`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsImmediatefamily
   * @request GET:/api/Persons/immediatefamily
   * @secure
   */
  personsImmediatefamily = (query: PersonsImmediatefamilyParams, params: RequestParams = {}) =>
    this.request<PersonsImmediatefamilyData, any>({
      path: `/api/Persons/immediatefamily`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsLifeevents2
   * @request GET:/api/Persons/lifeevents
   * @originalName personsLifeevents
   * @duplicate
   * @secure
   */
  personsLifeevents2 = (query: PersonsLifeevents2Params, params: RequestParams = {}) =>
    this.request<PersonsLifeevents2Data, any>({
      path: `/api/Persons/lifeevents`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonmedialist
   * @request GET:/api/Persons/{pageNumber}/{pageSize}/personmedialist
   * @secure
   */
  personsPersonmedialist = (
    { pageNumber, pageSize, ...query }: PersonsPersonmedialistParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPersonmedialistData, any>({
      path: `/api/Persons/${pageNumber}/${pageSize}/personmedialist`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsHintcount
   * @request GET:/api/Persons/hintcount
   * @secure
   */
  personsHintcount = (query: PersonsHintcountParams, params: RequestParams = {}) =>
    this.request<PersonsHintcountData, any>({
      path: `/api/Persons/hintcount`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsSavedrecords
   * @request GET:/api/Persons/{personId}/savedrecords
   * @secure
   */
  personsSavedrecords = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsSavedrecordsData, any>({
      path: `/api/Persons/${personId}/savedrecords`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPendingignoredhints
   * @request GET:/api/Persons/{personId}/pendingignoredhints
   * @secure
   */
  personsPendingignoredhints = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsPendingignoredhintsData, any>({
      path: `/api/Persons/${personId}/pendingignoredhints`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsIstreeaccessible
   * @request GET:/api/Persons/istreeaccessible
   * @secure
   */
  personsIstreeaccessible = (query: PersonsIstreeaccessibleParams, params: RequestParams = {}) =>
    this.request<PersonsIstreeaccessibleData, any>({
      path: `/api/Persons/istreeaccessible`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPersonlifeevents
   * @request GET:/api/Persons/{personId}/personlifeevents
   * @secure
   */
  personsPersonlifeevents = (
    { personId, ...query }: PersonsPersonlifeeventsParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPersonlifeeventsData, any>({
      path: `/api/Persons/${personId}/personlifeevents`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPotentialpersonhints
   * @request GET:/api/Persons/potentialpersonhints/{personId}
   * @secure
   */
  personsPotentialpersonhints = (
    { personId, ...query }: PersonsPotentialpersonhintsParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPotentialpersonhintsData, any>({
      path: `/api/Persons/potentialpersonhints/${personId}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsPedigreeprogressmeterinfo
   * @request GET:/api/Persons/pedigreeprogressmeterinfo/{treeId}/{personId}
   * @secure
   */
  personsPedigreeprogressmeterinfo = (
    treeId: string,
    personId: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsPedigreeprogressmeterinfoData, any>({
      path: `/api/Persons/pedigreeprogressmeterinfo/${treeId}/${personId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsGetFamilySearchHint
   * @request GET:/api/Persons/getFamilySearchHint
   * @secure
   */
  personsGetFamilySearchHint = (
    query: PersonsGetFamilySearchHintParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsGetFamilySearchHintData, any>({
      path: `/api/Persons/getFamilySearchHint`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsNewspaperMatches
   * @request GET:/api/Persons/{personId}/newspaper-matches
   * @secure
   */
  personsNewspaperMatches = (
    { personId, ...query }: PersonsNewspaperMatchesParams,
    params: RequestParams = {}
  ) =>
    this.request<PersonsNewspaperMatchesData, any>({
      path: `/api/Persons/${personId}/newspaper-matches`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsCollectionidofhighestscoringhint
   * @request GET:/api/Persons/{personId}/collectionidofhighestscoringhint
   * @secure
   */
  personsCollectionidofhighestscoringhint = (personId: string, params: RequestParams = {}) =>
    this.request<PersonsCollectionidofhighestscoringhintData, any>({
      path: `/api/Persons/${personId}/collectionidofhighestscoringhint`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name PersonsNewpersonhint
   * @request GET:/api/Persons/newpersonhint/{personId}/{potentialparentgender}
   * @secure
   */
  personsNewpersonhint = (
    personId: string,
    potentialparentgender: string,
    params: RequestParams = {}
  ) =>
    this.request<PersonsNewpersonhintData, any>({
      path: `/api/Persons/newpersonhint/${personId}/${potentialparentgender}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsMedia
   * @request POST:/api/v2/Persons/{personId}/media
   * @secure
   */
  v2PersonsMedia = (personId: string, data: V2PersonsMediaPayload, params: RequestParams = {}) =>
    this.request<V2PersonsMediaData, any>({
      path: `/api/v2/Persons/${personId}/media`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsMediaPeople
   * @request POST:/api/v2/Persons/{personId}/media/people
   * @secure
   */
  v2PersonsMediaPeople = (
    personId: string,
    data: V2PersonsMediaPeoplePayload,
    params: RequestParams = {}
  ) =>
    this.request<V2PersonsMediaPeopleData, any>({
      path: `/api/v2/Persons/${personId}/media/people`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsPersonMediaList
   * @request GET:/api/v2/Persons/{pageNumber}/person-media-list
   * @secure
   */
  v2PersonsPersonMediaList = (
    { pageNumber, ...query }: V2PersonsPersonMediaListParams,
    params: RequestParams = {}
  ) =>
    this.request<V2PersonsPersonMediaListData, any>({
      path: `/api/v2/Persons/${pageNumber}/person-media-list`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsLifeevents
   * @request GET:/api/v2/Persons/{treeId}/{personId}/lifeevents
   * @secure
   */
  v2PersonsLifeevents = (treeId: string, personId: string, params: RequestParams = {}) =>
    this.request<V2PersonsLifeeventsData, any>({
      path: `/api/v2/Persons/${treeId}/${personId}/lifeevents`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsAlllifeevents
   * @request GET:/api/v2/Persons/{personId}/alllifeevents
   * @secure
   */
  v2PersonsAlllifeevents = (
    { personId, ...query }: V2PersonsAlllifeeventsParams,
    params: RequestParams = {}
  ) =>
    this.request<V2PersonsAlllifeeventsData, any>({
      path: `/api/v2/Persons/${personId}/alllifeevents`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsAllLifeEvents
   * @request GET:/api/v2/Persons/{personId}/all-life-events
   * @secure
   */
  v2PersonsAllLifeEvents = (
    { personId, ...query }: V2PersonsAllLifeEventsParams,
    params: RequestParams = {}
  ) =>
    this.request<V2PersonsAllLifeEventsData, any>({
      path: `/api/v2/Persons/${personId}/all-life-events`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Persons
   * @name V2PersonsParentswithsiblings
   * @request GET:/api/v2/Persons/{treeId}/{personId}/parentswithsiblings
   * @secure
   */
  v2PersonsParentswithsiblings = (
    { treeId, personId, ...query }: V2PersonsParentswithsiblingsParams,
    params: RequestParams = {}
  ) =>
    this.request<V2PersonsParentswithsiblingsData, any>({
      path: `/api/v2/Persons/${treeId}/${personId}/parentswithsiblings`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
