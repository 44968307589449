/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  UsersAdb2CinfoData,
  UsersAuthorstoriesData,
  UsersAuthorstoriesParams,
  UsersAuthorstoriescountData,
  UsersAuthorstoriescountbycategoriesData,
  UsersAuthorstoriescountbycategoriesParams,
  UsersAuthproviderinfoData,
  UsersBidirectionaledgesData,
  UsersBooklistData,
  UsersCalculateRefundData,
  UsersCalculateRefundParams,
  UsersCalculateTaxData,
  UsersCalculateTaxParams,
  UsersCheckGroupCreationRightsData,
  UsersCheckNewspaperAccessData,
  UsersCheckNewspaperAccessParams,
  UsersCheckbillinginfoData,
  UsersCheckmediaexistData,
  UsersCompletedOrdersData,
  UsersCouponCodeAndPlanIdDiscountData,
  UsersCouponCodeDiscountData,
  UsersCouponDetailData,
  UsersCouponDetailParams,
  UsersCouponstatusData,
  UsersDetailData,
  UsersFollowersData,
  UsersFollowingData,
  UsersFollowingfollowersCountData,
  UsersFollowingstatusInfoData,
  UsersFollowingtopicsData,
  UsersFollowingtopicscountData,
  UsersGetbookpublishplandetailsData,
  UsersGetprimaryidentityproviderData,
  UsersGroupsData,
  UsersHomepagehintsData,
  UsersInfoData,
  UsersIsFamilySearchUserCreatedData,
  UsersIsPlanUpgradeData,
  UsersListAllPeopleData,
  UsersListinternalmediaData,
  UsersListinternalmediaParams,
  UsersListmediaData,
  UsersListmediaParams,
  UsersMemberDetailData,
  UsersMemberStoriesCountByCategoryData,
  UsersMemberStoriesData,
  UsersMemberStoriesParams,
  UsersMobilesupportedversionData,
  UsersNamedistributiondataData,
  UsersNamemeaningdataData,
  UsersNewspaperMatchesRecentData,
  UsersNewspaperMatchesRecentParams,
  UsersNewspaperMatchesTreeCountsData,
  UsersNewspaperMatchesTreeCountsPayload,
  UsersNonLoggedInMemberDetailData,
  UsersNotificationsData,
  UsersPendingGroupInvitesData,
  UsersRecentpeoplecardData,
  UsersRequestStatusData,
  UsersSearchpersonmediaData,
  UsersSearchpersonmediaParams,
  UsersSessiongroupmemberinfoData,
  UsersSponsoredGroupsData,
  UsersStoriesData,
  UsersStoriescountbystatusData,
  UsersStoryListForBookData,
  UsersSubscriptioninfoData,
  UsersTreesData,
  UsersTreesParams,
  UsersTreesWithHomePersonAssignedData,
  UsersTreesWithHomePersonAssignedParams,
  UsersTypeaheadAllData,
  UsersTypeaheadSearchPageData,
  UsersTypeaheadSearchPageParams,
  UsersUserSubscriptionDetailsData,
  UsersUserSubscriptionDetailsParams,
  UsersUserSubscriptionDetailsV2Data,
  UsersUserdetailData,
  V2UsersFsTokenData,
  V2UsersListInternalMediaData,
  V2UsersListInternalMediaParams,
  V2UsersListMediaData,
  V2UsersListMediaParams,
  V2UsersMediaData,
  V2UsersMediaPayload,
  V2UsersMediaPeopleData,
  V2UsersMediaPeoplePayload,
  V2UsersProjectsData,
  V2UsersProjectsParams,
  V2UsersStories2Data,
  V2UsersStories2Params,
  V2UsersStoriesData,
  V2UsersStoriesParams,
  V2UsersStoriesSearchData,
  V2UsersStoriesSearchDetailsData,
  V2UsersStoriesSearchDetailsPayload,
  V2UsersStoriesSearchPayload,
  V2UsersStoriesSearchPeopleData,
  V2UsersStoriesSearchPeoplePayload,
  V2UsersStoriesTaggedPeopleData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Users extends HttpClient {
  /**
   * No description
   *
   * @tags Users
   * @name UsersBooklist
   * @request GET:/api/Users/booklist
   * @secure
   */
  usersBooklist = (params: RequestParams = {}) =>
    this.request<UsersBooklistData, any>({
      path: `/api/Users/booklist`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCompletedOrders
   * @request GET:/api/Users/completed-orders
   * @secure
   */
  usersCompletedOrders = (params: RequestParams = {}) =>
    this.request<UsersCompletedOrdersData, any>({
      path: `/api/Users/completed-orders`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCouponstatus
   * @request GET:/api/Users/couponstatus
   * @secure
   */
  usersCouponstatus = (params: RequestParams = {}) =>
    this.request<UsersCouponstatusData, any>({
      path: `/api/Users/couponstatus`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersStoryListForBook
   * @request GET:/api/Users/StoryListForBook/{pageNumber}/{pageSize}
   * @secure
   */
  usersStoryListForBook = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<UsersStoryListForBookData, any>({
      path: `/api/Users/StoryListForBook/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersTrees
   * @request GET:/api/Users/trees
   * @secure
   */
  usersTrees = (query: UsersTreesParams, params: RequestParams = {}) =>
    this.request<UsersTreesData, any>({
      path: `/api/Users/trees`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersTreesWithHomePersonAssigned
   * @request GET:/api/Users/treesWithHomePersonAssigned
   * @secure
   */
  usersTreesWithHomePersonAssigned = (
    query: UsersTreesWithHomePersonAssignedParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersTreesWithHomePersonAssignedData, any>({
      path: `/api/Users/treesWithHomePersonAssigned`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersInfo
   * @request GET:/api/Users/{userId}/info
   * @secure
   */
  usersInfo = (userId: string, params: RequestParams = {}) =>
    this.request<UsersInfoData, any>({
      path: `/api/Users/${userId}/info`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersAdb2Cinfo
   * @request GET:/api/Users/{userId}/adb2cinfo
   * @secure
   */
  usersAdb2Cinfo = (userId: string, params: RequestParams = {}) =>
    this.request<UsersAdb2CinfoData, any>({
      path: `/api/Users/${userId}/adb2cinfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersAuthproviderinfo
   * @request GET:/api/Users/{userId}/authproviderinfo
   * @secure
   */
  usersAuthproviderinfo = (userId: string, params: RequestParams = {}) =>
    this.request<UsersAuthproviderinfoData, any>({
      path: `/api/Users/${userId}/authproviderinfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersAuthorstories
   * @request GET:/api/Users/{pageNumber}/{pageSize}/authorstories
   * @secure
   */
  usersAuthorstories = (
    { pageNumber, pageSize, ...query }: UsersAuthorstoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersAuthorstoriesData, any>({
      path: `/api/Users/${pageNumber}/${pageSize}/authorstories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersStories
   * @request GET:/api/Users/stories/{pageNumber}/{pageSize}
   * @secure
   */
  usersStories = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<UsersStoriesData, any>({
      path: `/api/Users/stories/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersBidirectionaledges
   * @request POST:/api/Users/bidirectionaledges
   * @secure
   */
  usersBidirectionaledges = (params: RequestParams = {}) =>
    this.request<UsersBidirectionaledgesData, any>({
      path: `/api/Users/bidirectionaledges`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersTypeaheadSearchPage
   * @request GET:/api/Users/typeahead/search/{requestId}/page/{pageNumber}/{searchString}
   * @secure
   */
  usersTypeaheadSearchPage = (
    { requestId, pageNumber, searchString, ...query }: UsersTypeaheadSearchPageParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersTypeaheadSearchPageData, any>({
      path: `/api/Users/typeahead/search/${requestId}/page/${pageNumber}/${searchString}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersTypeaheadAll
   * @request GET:/api/Users/typeahead/all
   * @secure
   */
  usersTypeaheadAll = (params: RequestParams = {}) =>
    this.request<UsersTypeaheadAllData, any>({
      path: `/api/Users/typeahead/all`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersAuthorstoriescount
   * @request GET:/api/Users/authorstoriescount
   * @secure
   */
  usersAuthorstoriescount = (params: RequestParams = {}) =>
    this.request<UsersAuthorstoriescountData, any>({
      path: `/api/Users/authorstoriescount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersAuthorstoriescountbycategories
   * @request GET:/api/Users/authorstoriescountbycategories
   * @secure
   */
  usersAuthorstoriescountbycategories = (
    query: UsersAuthorstoriescountbycategoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersAuthorstoriescountbycategoriesData, any>({
      path: `/api/Users/authorstoriescountbycategories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersListAllPeople
   * @request GET:/api/Users/listAllPeople
   * @secure
   */
  usersListAllPeople = (params: RequestParams = {}) =>
    this.request<UsersListAllPeopleData, any>({
      path: `/api/Users/listAllPeople`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersRecentpeoplecard
   * @request GET:/api/Users/recentpeoplecard
   * @secure
   */
  usersRecentpeoplecard = (params: RequestParams = {}) =>
    this.request<UsersRecentpeoplecardData, any>({
      path: `/api/Users/recentpeoplecard`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersUserdetail
   * @request GET:/api/Users/{userId}/userdetail
   * @secure
   */
  usersUserdetail = (userId: string, params: RequestParams = {}) =>
    this.request<UsersUserdetailData, any>({
      path: `/api/Users/${userId}/userdetail`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersDetail
   * @request GET:/api/Users/{userId}/detail
   * @secure
   */
  usersDetail = (userId: string, params: RequestParams = {}) =>
    this.request<UsersDetailData, any>({
      path: `/api/Users/${userId}/detail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersNotifications
   * @request GET:/api/Users/notifications/{pageNumber}/{pageSize}
   * @secure
   */
  usersNotifications = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<UsersNotificationsData, any>({
      path: `/api/Users/notifications/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersFollowers
   * @request GET:/api/Users/followers/{pageNumber}/{pageSize}
   * @secure
   */
  usersFollowers = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<UsersFollowersData, any>({
      path: `/api/Users/followers/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersFollowingstatusInfo
   * @request GET:/api/Users/followingstatusInfo/{followingUserId}
   * @secure
   */
  usersFollowingstatusInfo = (followingUserId: string, params: RequestParams = {}) =>
    this.request<UsersFollowingstatusInfoData, any>({
      path: `/api/Users/followingstatusInfo/${followingUserId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersFollowing
   * @request GET:/api/Users/following/{pageNumber}/{pageSize}
   * @secure
   */
  usersFollowing = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<UsersFollowingData, any>({
      path: `/api/Users/following/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersFollowingfollowersCount
   * @request GET:/api/Users/followingfollowersCount
   * @secure
   */
  usersFollowingfollowersCount = (params: RequestParams = {}) =>
    this.request<UsersFollowingfollowersCountData, any>({
      path: `/api/Users/followingfollowersCount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersFollowingtopics
   * @request GET:/api/Users/followingtopics/{pageNumber}/{pageSize}
   * @secure
   */
  usersFollowingtopics = (pageNumber: number, pageSize: number, params: RequestParams = {}) =>
    this.request<UsersFollowingtopicsData, any>({
      path: `/api/Users/followingtopics/${pageNumber}/${pageSize}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersFollowingtopicscount
   * @request GET:/api/Users/followingtopicscount
   * @secure
   */
  usersFollowingtopicscount = (params: RequestParams = {}) =>
    this.request<UsersFollowingtopicscountData, any>({
      path: `/api/Users/followingtopicscount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersSubscriptioninfo
   * @request GET:/api/Users/subscriptioninfo
   * @secure
   */
  usersSubscriptioninfo = (params: RequestParams = {}) =>
    this.request<UsersSubscriptioninfoData, any>({
      path: `/api/Users/subscriptioninfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersSessiongroupmemberinfo
   * @request GET:/api/Users/sessiongroupmemberinfo
   * @secure
   */
  usersSessiongroupmemberinfo = (params: RequestParams = {}) =>
    this.request<UsersSessiongroupmemberinfoData, any>({
      path: `/api/Users/sessiongroupmemberinfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersMemberStories
   * @request GET:/api/Users/{memberId}/{pageNumber}/{pageSize}/memberStories
   * @secure
   */
  usersMemberStories = (
    { memberId, pageNumber, pageSize, ...query }: UsersMemberStoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersMemberStoriesData, any>({
      path: `/api/Users/${memberId}/${pageNumber}/${pageSize}/memberStories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersMemberStoriesCountByCategory
   * @request GET:/api/Users/{memberId}/memberStoriesCountByCategory
   * @secure
   */
  usersMemberStoriesCountByCategory = (memberId: string, params: RequestParams = {}) =>
    this.request<UsersMemberStoriesCountByCategoryData, any>({
      path: `/api/Users/${memberId}/memberStoriesCountByCategory`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersMemberDetail
   * @request GET:/api/Users/{memberId}/memberDetail
   * @secure
   */
  usersMemberDetail = (memberId: string, params: RequestParams = {}) =>
    this.request<UsersMemberDetailData, any>({
      path: `/api/Users/${memberId}/memberDetail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersListmedia
   * @request GET:/api/Users/{pageNumber}/{pageSize}/listmedia
   * @secure
   */
  usersListmedia = (
    { pageNumber, pageSize, ...query }: UsersListmediaParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersListmediaData, any>({
      path: `/api/Users/${pageNumber}/${pageSize}/listmedia`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersListinternalmedia
   * @request GET:/api/Users/{pageNumber}/{pageSize}/listinternalmedia
   * @secure
   */
  usersListinternalmedia = (
    { pageNumber, pageSize, ...query }: UsersListinternalmediaParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersListinternalmediaData, any>({
      path: `/api/Users/${pageNumber}/${pageSize}/listinternalmedia`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCheckmediaexist
   * @request GET:/api/Users/checkmediaexist
   * @secure
   */
  usersCheckmediaexist = (params: RequestParams = {}) =>
    this.request<UsersCheckmediaexistData, any>({
      path: `/api/Users/checkmediaexist`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersSearchpersonmedia
   * @request GET:/api/Users/{pageNumber}/{pageSize}/searchpersonmedia
   * @secure
   */
  usersSearchpersonmedia = (
    { pageNumber, pageSize, ...query }: UsersSearchpersonmediaParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersSearchpersonmediaData, any>({
      path: `/api/Users/${pageNumber}/${pageSize}/searchpersonmedia`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersGroups
   * @request GET:/api/Users/groups
   * @secure
   */
  usersGroups = (params: RequestParams = {}) =>
    this.request<UsersGroupsData, any>({
      path: `/api/Users/groups`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersSponsoredGroups
   * @request GET:/api/Users/sponsoredGroups
   * @secure
   */
  usersSponsoredGroups = (params: RequestParams = {}) =>
    this.request<UsersSponsoredGroupsData, any>({
      path: `/api/Users/sponsoredGroups`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCheckGroupCreationRights
   * @request GET:/api/Users/checkGroupCreationRights
   * @secure
   */
  usersCheckGroupCreationRights = (params: RequestParams = {}) =>
    this.request<UsersCheckGroupCreationRightsData, any>({
      path: `/api/Users/checkGroupCreationRights`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersPendingGroupInvites
   * @request GET:/api/Users/pendingGroupInvites
   * @secure
   */
  usersPendingGroupInvites = (params: RequestParams = {}) =>
    this.request<UsersPendingGroupInvitesData, any>({
      path: `/api/Users/pendingGroupInvites`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersNonLoggedInMemberDetail
   * @request GET:/api/Users/{invitationId}/{memberId}/nonLoggedInMemberDetail
   * @secure
   */
  usersNonLoggedInMemberDetail = (
    invitationId: string,
    memberId: string,
    params: RequestParams = {}
  ) =>
    this.request<UsersNonLoggedInMemberDetailData, any>({
      path: `/api/Users/${invitationId}/${memberId}/nonLoggedInMemberDetail`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCalculateTax
   * @request GET:/api/Users/calculateTax
   * @secure
   */
  usersCalculateTax = (query: UsersCalculateTaxParams, params: RequestParams = {}) =>
    this.request<UsersCalculateTaxData, any>({
      path: `/api/Users/calculateTax`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCalculateRefund
   * @request GET:/api/Users/calculateRefund
   * @secure
   */
  usersCalculateRefund = (query: UsersCalculateRefundParams, params: RequestParams = {}) =>
    this.request<UsersCalculateRefundData, any>({
      path: `/api/Users/calculateRefund`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersNewspaperMatchesRecent
   * @request GET:/api/Users/newspaper-matches/recent
   * @secure
   */
  usersNewspaperMatchesRecent = (
    query: UsersNewspaperMatchesRecentParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersNewspaperMatchesRecentData, any>({
      path: `/api/Users/newspaper-matches/recent`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersNewspaperMatchesTreeCounts
   * @request POST:/api/Users/newspaper-matches/tree-counts
   * @secure
   */
  usersNewspaperMatchesTreeCounts = (
    data: UsersNewspaperMatchesTreeCountsPayload,
    params: RequestParams = {}
  ) =>
    this.request<UsersNewspaperMatchesTreeCountsData, any>({
      path: `/api/Users/newspaper-matches/tree-counts`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersUserSubscriptionDetails
   * @request GET:/api/Users/userSubscriptionDetails
   * @secure
   */
  usersUserSubscriptionDetails = (
    query: UsersUserSubscriptionDetailsParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersUserSubscriptionDetailsData, any>({
      path: `/api/Users/userSubscriptionDetails`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersUserSubscriptionDetailsV2
   * @request GET:/api/Users/userSubscriptionDetailsV2
   * @secure
   */
  usersUserSubscriptionDetailsV2 = (params: RequestParams = {}) =>
    this.request<UsersUserSubscriptionDetailsV2Data, any>({
      path: `/api/Users/userSubscriptionDetailsV2`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCheckNewspaperAccess
   * @request GET:/api/Users/checkNewspaperAccess
   * @secure
   */
  usersCheckNewspaperAccess = (
    query: UsersCheckNewspaperAccessParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersCheckNewspaperAccessData, any>({
      path: `/api/Users/checkNewspaperAccess`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersHomepagehints
   * @request GET:/api/Users/homepagehints
   * @secure
   */
  usersHomepagehints = (params: RequestParams = {}) =>
    this.request<UsersHomepagehintsData, any>({
      path: `/api/Users/homepagehints`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCouponCodeDiscount
   * @request GET:/api/Users/{couponCode}/couponCodeDiscount
   * @secure
   */
  usersCouponCodeDiscount = (couponCode: string, params: RequestParams = {}) =>
    this.request<UsersCouponCodeDiscountData, any>({
      path: `/api/Users/${couponCode}/couponCodeDiscount`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersRequestStatus
   * @request GET:/api/Users/requestStatus/{requestId}
   * @secure
   */
  usersRequestStatus = (requestId: string, params: RequestParams = {}) =>
    this.request<UsersRequestStatusData, any>({
      path: `/api/Users/requestStatus/${requestId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersMobilesupportedversion
   * @request GET:/api/Users/mobilesupportedversion
   * @secure
   */
  usersMobilesupportedversion = (params: RequestParams = {}) =>
    this.request<UsersMobilesupportedversionData, any>({
      path: `/api/Users/mobilesupportedversion`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCouponCodeAndPlanIdDiscount
   * @request GET:/api/Users/{couponCode}/{planId}/couponCodeAndPlanIdDiscount
   * @secure
   */
  usersCouponCodeAndPlanIdDiscount = (
    couponCode: string,
    planId: string,
    params: RequestParams = {}
  ) =>
    this.request<UsersCouponCodeAndPlanIdDiscountData, any>({
      path: `/api/Users/${couponCode}/${planId}/couponCodeAndPlanIdDiscount`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCouponDetail
   * @request GET:/api/Users/couponDetail/{couponCode}
   * @secure
   */
  usersCouponDetail = (
    { couponCode, ...query }: UsersCouponDetailParams,
    params: RequestParams = {}
  ) =>
    this.request<UsersCouponDetailData, any>({
      path: `/api/Users/couponDetail/${couponCode}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersGetprimaryidentityprovider
   * @request GET:/api/Users/{email}/getprimaryidentityprovider
   * @secure
   */
  usersGetprimaryidentityprovider = (email: string, params: RequestParams = {}) =>
    this.request<UsersGetprimaryidentityproviderData, any>({
      path: `/api/Users/${email}/getprimaryidentityprovider`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersNamedistributiondata
   * @request GET:/api/Users/{country}/{surname}/namedistributiondata
   * @secure
   */
  usersNamedistributiondata = (country: string, surname: string, params: RequestParams = {}) =>
    this.request<UsersNamedistributiondataData, any>({
      path: `/api/Users/${country}/${surname}/namedistributiondata`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersNamemeaningdata
   * @request GET:/api/Users/{country}/{surname}/namemeaningdata
   * @secure
   */
  usersNamemeaningdata = (country: string, surname: string, params: RequestParams = {}) =>
    this.request<UsersNamemeaningdataData, any>({
      path: `/api/Users/${country}/${surname}/namemeaningdata`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersStoriescountbystatus
   * @request GET:/api/Users/storiescountbystatus/{status}
   * @secure
   */
  usersStoriescountbystatus = (status: string, params: RequestParams = {}) =>
    this.request<UsersStoriescountbystatusData, any>({
      path: `/api/Users/storiescountbystatus/${status}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersGetbookpublishplandetails
   * @request GET:/api/Users/getbookpublishplandetails
   * @secure
   */
  usersGetbookpublishplandetails = (params: RequestParams = {}) =>
    this.request<UsersGetbookpublishplandetailsData, any>({
      path: `/api/Users/getbookpublishplandetails`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersCheckbillinginfo
   * @request GET:/api/Users/checkbillinginfo
   * @secure
   */
  usersCheckbillinginfo = (params: RequestParams = {}) =>
    this.request<UsersCheckbillinginfoData, any>({
      path: `/api/Users/checkbillinginfo`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersIsFamilySearchUserCreated
   * @request GET:/api/Users/{familySearchId}/IsFamilySearchUserCreated
   * @secure
   */
  usersIsFamilySearchUserCreated = (familySearchId: string, params: RequestParams = {}) =>
    this.request<UsersIsFamilySearchUserCreatedData, any>({
      path: `/api/Users/${familySearchId}/IsFamilySearchUserCreated`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name UsersIsPlanUpgrade
   * @request GET:/api/Users/is-plan-upgrade/{isFreeTrial}/{previousPlanId}/{currentPlanId}
   * @secure
   */
  usersIsPlanUpgrade = (
    isFreeTrial: boolean,
    previousPlanId: number,
    currentPlanId: number,
    params: RequestParams = {}
  ) =>
    this.request<UsersIsPlanUpgradeData, any>({
      path: `/api/Users/is-plan-upgrade/${isFreeTrial}/${previousPlanId}/${currentPlanId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersMedia
   * @request POST:/api/v2/Users/media
   * @secure
   */
  v2UsersMedia = (data: V2UsersMediaPayload, params: RequestParams = {}) =>
    this.request<V2UsersMediaData, any>({
      path: `/api/v2/Users/media`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersMediaPeople
   * @request POST:/api/v2/Users/media/people
   * @secure
   */
  v2UsersMediaPeople = (data: V2UsersMediaPeoplePayload, params: RequestParams = {}) =>
    this.request<V2UsersMediaPeopleData, any>({
      path: `/api/v2/Users/media/people`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersListMedia
   * @request GET:/api/v2/Users/{pageNumber}/list-media
   * @secure
   */
  v2UsersListMedia = (
    { pageNumber, ...query }: V2UsersListMediaParams,
    params: RequestParams = {}
  ) =>
    this.request<V2UsersListMediaData, any>({
      path: `/api/v2/Users/${pageNumber}/list-media`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersListInternalMedia
   * @request GET:/api/v2/Users/{pageNumber}/list-internal-media
   * @secure
   */
  v2UsersListInternalMedia = (
    { pageNumber, ...query }: V2UsersListInternalMediaParams,
    params: RequestParams = {}
  ) =>
    this.request<V2UsersListInternalMediaData, any>({
      path: `/api/v2/Users/${pageNumber}/list-internal-media`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersStories
   * @request GET:/api/v2/Users/stories/{pageNumber}
   * @secure
   */
  v2UsersStories = ({ pageNumber, ...query }: V2UsersStoriesParams, params: RequestParams = {}) =>
    this.request<V2UsersStoriesData, any>({
      path: `/api/v2/Users/stories/${pageNumber}`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersStories2
   * @request GET:/api/v2/Users/stories
   * @originalName v2UsersStories
   * @duplicate
   * @secure
   */
  v2UsersStories2 = (query: V2UsersStories2Params, params: RequestParams = {}) =>
    this.request<V2UsersStories2Data, any>({
      path: `/api/v2/Users/stories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersStoriesSearch
   * @request POST:/api/v2/Users/stories/search
   * @deprecated
   * @secure
   */
  v2UsersStoriesSearch = (data: V2UsersStoriesSearchPayload, params: RequestParams = {}) =>
    this.request<V2UsersStoriesSearchData, any>({
      path: `/api/v2/Users/stories/search`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersStoriesSearchDetails
   * @request POST:/api/v2/Users/stories/search/details
   * @deprecated
   * @secure
   */
  v2UsersStoriesSearchDetails = (
    data: V2UsersStoriesSearchDetailsPayload,
    params: RequestParams = {}
  ) =>
    this.request<V2UsersStoriesSearchDetailsData, any>({
      path: `/api/v2/Users/stories/search/details`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersStoriesSearchPeople
   * @request POST:/api/v2/Users/stories/search/people
   * @deprecated
   * @secure
   */
  v2UsersStoriesSearchPeople = (
    data: V2UsersStoriesSearchPeoplePayload,
    params: RequestParams = {}
  ) =>
    this.request<V2UsersStoriesSearchPeopleData, any>({
      path: `/api/v2/Users/stories/search/people`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersStoriesTaggedPeople
   * @request GET:/api/v2/Users/stories/tagged-people
   * @secure
   */
  v2UsersStoriesTaggedPeople = (params: RequestParams = {}) =>
    this.request<V2UsersStoriesTaggedPeopleData, any>({
      path: `/api/v2/Users/stories/tagged-people`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersFsToken
   * @request GET:/api/v2/Users/fs-token/{authorizationCode}/{redirectUrl}
   * @secure
   */
  v2UsersFsToken = (authorizationCode: string, redirectUrl: string, params: RequestParams = {}) =>
    this.request<V2UsersFsTokenData, any>({
      path: `/api/v2/Users/fs-token/${authorizationCode}/${redirectUrl}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags Users
   * @name V2UsersProjects
   * @request GET:/api/v2/Users/projects
   * @secure
   */
  v2UsersProjects = (query: V2UsersProjectsParams, params: RequestParams = {}) =>
    this.request<V2UsersProjectsData, any>({
      path: `/api/v2/Users/projects`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
