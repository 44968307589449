import React from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "assets/images/logo.svg";
import Button from "components/Button";
import Icon from "components/Icon";
import Translator from "components/Translator";
import "./mobile-menu.css";
import { copyright } from 'shared-logics';
import { useFeatureFlags } from 'services/featureFlag';
const MobileMenu = ({ isActive, handleToggle, handleVerifiedCookiedBasedModal, handleLogin, handleSignup, marketingURL }) => {
  const { pathname } = useLocation();
  const hideStartFreeTrial = pathname.includes("/payment/bundle/trial");
  const { enabled: FF_NPAP_ManagePlan }= useFeatureFlags("FF_NPAP_ManagePlan");
  
  return (
    <>
      <div className={`lohp sidebar-overly ${isActive ? "" : "overly-in"}`} onClick={handleToggle}></div>
      <aside className={`lohp-sidebar-panel sidebar-panel ${isActive ? "" : "sidebar-in"}`}>
        <div className="logo-block">
          <Link to="/" tabIndex={-1} className="outline-none focus:outline-none">
            <img src={logo} alt="Storied" className="lohp-logo" width={113} height={24} />{" "}
          </Link>
          <button type="button" className="lohp-close" onClick={handleToggle} aria-label="menu">
            <Icon type={"close"} color="default" size="medium" />
          </button>
        </div>
        <div className="menu-holder">
          <ul className="sidebar-menu">
            <li>
              <div className="group-title">
                <Translator tkey="loggedoutHomepage.nav.features" />
              </div>
            </li>
            <li>
              <NavLink to={`/stories`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="mIconBookPersion" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.stories" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/storiedbooks`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="story2" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.storiedbooks" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/groups`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="groups" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.groups" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/family-trees`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="mIconTree" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.FamilyTrees" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/people`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="people" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.Connections" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/historical-record-search`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="recordSearch" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.HistoricalSearch" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/newspaper-search`} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="newspaperSearch" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.NewspaperSearch" />
                </span>
              </NavLink>
            </li>
          </ul>
          <ul className="sidebar-menu">
            <li>
              <div className="group-title">
                <Translator tkey="loggedoutHomepage.nav.resources" />
              </div>
            </li>
            <li>
              <NavLink to={FF_NPAP_ManagePlan ? '/trial-offers' : '/plans'} className="m-link" activeClassName="active" onClick={handleToggle}>
                <figure className="m-icon">
                  <Icon size="medium" type="card" />
                </figure>
                <span className="m-title">
                  <Translator tkey="loggedoutHomepage.nav.plans" />
                </span>
              </NavLink>
            </li>
          </ul>

          <div className="sidebar-footer">
            <div className={`w-full mb-4 mx-px px-px ${ hideStartFreeTrial ? "hidden": ""}`}>
              <div className="w-full px-px">
                <Button
                  id="signup-btn"
                  type="primary"
                  title={<Translator tkey="title.StartFreeTrial" />}
                  fontWeight="medium"
                  size="large"
                  width="full"
                  handleClick={() => {
                    handleVerifiedCookiedBasedModal(handleSignup, null, marketingURL,true);
                  }}
                />
              </div>
            </div>
            <div className="w-full mb-4 mx-px px-px">
              <div className="w-full mx-px px-px">
                <Button id="signin-btn" type="gray-3" title={<Translator tkey="loggedoutHomepage.nav.signIn" />} fontWeight="medium" width="full" size="large" fontColor="blue-5" handleClick={() => handleVerifiedCookiedBasedModal(handleLogin)} />
              </div>
            </div>
            <div className="footer-nav mb-1">
              <NavLink to="/about" className="footer-link" activeClassName="active" onClick={handleToggle}>
                <Translator tkey="loggedoutHomepage.nav.about" />
              </NavLink>
              <NavLink to="/resources" className="footer-link" activeClassName="active" rel="noreferrer" onClick={handleToggle}>
                <Translator tkey="loggedoutHomepage.nav.resources" />
              </NavLink>
              <NavLink to="/privacy" className="footer-link" activeClassName="active" onClick={handleToggle}>
                <Translator tkey="loggedoutHomepage.nav.privacy" />
              </NavLink>
              <NavLink to="/terms" className="footer-link" activeClassName="active" onClick={handleToggle}>
                <Translator tkey="loggedoutHomepage.nav.terms" />
              </NavLink>
            </div>
            <div className="footer-copy">{copyright()}</div>
          </div>
        </div>
      </aside>
    </>
  )
}

export default MobileMenu